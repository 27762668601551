exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-contentful-blog-post-slug-js": () => import("./../../../src/pages/blog/{contentfulBlogPost.slug}.js" /* webpackChunkName: "component---src-pages-blog-contentful-blog-post-slug-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cannabis-cultivation-license-js": () => import("./../../../src/pages/cannabis/cultivation-license.js" /* webpackChunkName: "component---src-pages-cannabis-cultivation-license-js" */),
  "component---src-pages-cannabis-dispensing-license-js": () => import("./../../../src/pages/cannabis/dispensing-license.js" /* webpackChunkName: "component---src-pages-cannabis-dispensing-license-js" */),
  "component---src-pages-cannabis-retail-sales-js": () => import("./../../../src/pages/cannabis/retail-sales.js" /* webpackChunkName: "component---src-pages-cannabis-retail-sales-js" */),
  "component---src-pages-cannabis-scoring-criteria-js": () => import("./../../../src/pages/cannabis/scoring-criteria.js" /* webpackChunkName: "component---src-pages-cannabis-scoring-criteria-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-dental-js": () => import("./../../../src/pages/industries/dental.js" /* webpackChunkName: "component---src-pages-industries-dental-js" */),
  "component---src-pages-industries-healthcare-js": () => import("./../../../src/pages/industries/healthcare.js" /* webpackChunkName: "component---src-pages-industries-healthcare-js" */),
  "component---src-pages-industries-law-firms-js": () => import("./../../../src/pages/industries/law-firms.js" /* webpackChunkName: "component---src-pages-industries-law-firms-js" */),
  "component---src-pages-industries-real-estate-js": () => import("./../../../src/pages/industries/real-estate.js" /* webpackChunkName: "component---src-pages-industries-real-estate-js" */),
  "component---src-pages-industries-small-businesses-js": () => import("./../../../src/pages/industries/small-businesses.js" /* webpackChunkName: "component---src-pages-industries-small-businesses-js" */),
  "component---src-pages-services-accounting-services-js": () => import("./../../../src/pages/services/accounting-services.js" /* webpackChunkName: "component---src-pages-services-accounting-services-js" */),
  "component---src-pages-services-capital-gains-tax-js": () => import("./../../../src/pages/services/capital-gains-tax.js" /* webpackChunkName: "component---src-pages-services-capital-gains-tax-js" */),
  "component---src-pages-services-tax-consulting-js": () => import("./../../../src/pages/services/tax-consulting.js" /* webpackChunkName: "component---src-pages-services-tax-consulting-js" */),
  "component---src-pages-services-tax-services-js": () => import("./../../../src/pages/services/tax-services.js" /* webpackChunkName: "component---src-pages-services-tax-services-js" */),
  "component---src-pages-video-consulting-js": () => import("./../../../src/pages/video-consulting.js" /* webpackChunkName: "component---src-pages-video-consulting-js" */)
}

